import { ChartEvent, ChartOptions } from "chart.js";

export const LIST_CHART_OPTIONS: ChartOptions<"doughnut"> = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: "60%",

  plugins: {
    legend: {
      display: false,
    },
  },
  onClick: (e: ChartEvent) => e.native?.preventDefault(),
  onHover: (e: ChartEvent) => e.native?.preventDefault(),
};

export const ASSESMENTS_CHART_OPTIONS: ChartOptions<"doughnut"> = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: "80%",

  plugins: {
    legend: {
      display: false,
    },
  },
  onClick: (e: ChartEvent) => e.native?.preventDefault(),
  onHover: (e: ChartEvent) => e.native?.preventDefault(),
};
